<script>
export default {
  components: {
    Terms: () => import('@/components/Terms'),
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    defineSlice({
      name: { label: 'Special Offer Card', group: 'playaresorts' },
      fields: {
        country: { label: 'Country', type: 'text', enabled: false, name: 'Country' },
        brand: { label: 'Brand', type: 'text', enabled: false, name: 'Brand' },
        titleAbove: { label: 'Title Above', type: 'text', enabled: false, name: 'titleAbove' },
        title: { label: 'Title', type: 'text', enabled: false, name: 'title' },
        subtitle: { label: 'Subtitle', type: 'text', enabled: false, name: 'subtitle' },
        image: { label: 'Media', type: 'media', breakpoints: { default: { width: 800 } }, name: 'image' },
        resortLogo: { label: 'Resort Logo', type: 'media', breakpoints: { default: { width: 400 } }, name: 'Resort Logo' },
        content: { label: 'Content', type: 'wysiwyg', enabled: false, name: 'content' },
        percentOff: { label: 'Percent Off', type: 'text', name: 'percentOff' },
        extraSavings: { label: 'Extra Savings', type: 'text', name: 'extraSavings' },
        hideBookNow: { label: 'Hide Book Now', type: 'checkbox', name: 'hideBookNow' },
        terms: { label: 'Terms and Conditions', type: 'checkbox', name: 'terms' },
        prettyEndDate: { label: 'End Date', type: 'text', name: 'prettyEndDate' },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div class="md:px-8 mb-10 flex justify-stretch items-stretch">
    <div class="bg-abs-white min-w-full">
      <div v-if="fields?.titleAbove?.value" class="block uppercase mb-2">
        {{ fields?.title?.value }}
      </div>
      <div class="aspect-video">
        <VoixMedia
          v-if="fields?.image?.value"
          :field="fields?.image"
          class="object-cover object-center h-full w-full"
        />

        <div class="px-4 md:px-8 pb-8">
          <div class="my-8">
            <span
              v-if="!fields?.titleAbove?.value"
              class="block uppercase mb-1 text-sm"
            >{{ fields?.title?.value }}</span>
            <div class="text-3xl font-bold mb-4 title" v-html="fields?.subtitle?.value" />
            <div class="special-html-override" v-html="fields?.content?.value" />
          </div>

          <div class="flex flex-col items-center mb-8">
            <div
              v-if="fields?.percentOff?.value"
              class="flex items-center uppercase mb-2"
            >
              <div class="text-5xl font-bold">
                Save
              </div>
              <div class="flex flex-col text-xs mx-1">
                <div>Up</div>
                <div>To</div>
              </div>
              <div class="text-5xl font-bold">
                {{ fields?.percentOff?.value }}
              </div>
            </div>
            <div
              v-if="fields?.extraSavings?.value"
              class="font-bold"
              v-html="fields?.extraSavings?.value"
            />
          </div>

          <PlayaBooking v-if="fields?.hideBookNow?.value || fields?.hideBookNow?.value === 0">
            <button class="btn my-4 text-white uppercase bg-nonPhotoBlue">
              {{ $t('book-now') }}
            </button>
          </PlayaBooking>

          <Terms :terms="fields?.terms?.value">
            <button class="font-bold underline underline-offset-2 mb-2">
              {{ $t('terms-and-conditions') }}
            </button>
          </Terms>
          <div v-if="fields?.prettyEndDate?.value">
            {{ $t('offer-expires') }}: {{ fields?.prettyEndDate?.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.special-html-override {
  h1, h2, h3, h4, h5, h6 {
    @apply font-sans font-normal text-base #{!important};
  }
}
</style>
