<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      description: 'Introduction with an image and two columns below it',
      fields: {
        introduction: { type: 'wysiwyg', label: 'Introduction' },
        column1: { type: 'wysiwyg', label: 'Column 1' },
        column2: { type: 'wysiwyg', label: 'Column 2' },
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: {
            default: {
              width: 800,
            },
          },
        },
        pink: { type: 'checkbox', label: 'Pink instead of purple?', default: false },
      },
      name: { label: 'Swoosh Masked Images And Columns', group: 'Hyatt' },
      templates: [{ label: 'Swoosh Masked Images And Columns', fields: { column1: { value: '<ul>\n<li>Infinity Pool&nbsp;<\/li><li>Beach Butlers&nbsp;<\/li><li>Unlimited Dining&nbsp;<\/li><li>Endless Drinks&nbsp;<\/li><li>6 Restaurants&nbsp;<\/li><li>5 Bars &amp; Lounges&nbsp;<\/li><li>Premium Top Shelf Wines &amp; Liquors&nbsp;<\/li><li>Oceanfront Dining<\/li><li>24-hour Room Service&nbsp;<\/li><li>Poolside or Oceanfront Beach Cabanas&nbsp;<\/li><\/ul> <br\/><br\/>' }, column2: { value: '<ul><li>Bathrobes &amp; Slippers<\/li><li>Free Wi-Fi<\/li><li>State-of-the-art Sky Gym<\/li><li>Minibar stocked daily<\/li><li>Water Volleyball<\/li><li>Aqua Gym Class<\/li><li>Live Entertainment<\/li><\/ul>' }, image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/styled\/www.resortsbyhyatt.com\/web\/hyatt-curved-collage-802-415-d4c1284e37c87d8ccfcff4df2a5be1001549048712.jpg' }, introduction: { value: '<p>All-inclusive should exceed expectations. That\u2019s why Hyatt Zilara guests can revel in unexpected luxuries like innovative accommodations, and array of authentic dining venues. Stunning oceanfront settings, vibrant culture, live entertainment and so much more\u2014it\u2019s always included.<\/p>' } } }],
    })
  },
}
</script>

<template>
  <div class="bg-[#fafafa] border-b-8" :class="{ 'border-zilara-cancun-indigo': !fields.pink.value, 'border-ziva-pink': fields.pink.value }">
    <div class="w-full p-12 px-16 md:p-8 p-2 px-8 container text-center flex flex-col justify-center items-center">
      <div class="container w-full md:w-3/4 py-8">
        <div class="leading-normal" v-html="fields.introduction.value" />
      </div>
    </div>
    <div class="flex flex-col md:flex-row justify-start items-end">
      <div class="min-w-1/2 flex items-end">
        <VoixMedia :field="fields.image" />
      </div>
      <div class="md:mb-8 p-4 w-full flex flex-col md:flex-row items-center">
        <div class="w-full leading-normal" v-html="fields.column1.value" />
        <div class="w-full leading-normal" v-html="fields.column2.value" />
      </div>
    </div>
  </div>
</template>
