<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      description: 'Weddings Card',
      preview: 'SlicesWeddingsCard.jpg',
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: {
            default: {
              width: 500,
              height: 500,
            },
          },
        },
        title: {
          type: 'text',
          label: 'Title',
          default: 'Big Day Preview',
        },
        copy: {
          type: 'wysiwyg',
          label: 'Main Copy',
          default: '<p style="text-align: center;font-size:.9em">Before confirming your date, preview your big day in paradise during the ultimate escape at Hyatt Zilara Riviera Cancun.</p>',
        },
        learnMore: {
          type: 'wysiwyg',
          label: 'Learn More Modal',
          enabled: true,
        },
        link: {
          type: 'link',
          label: 'Link',
          default: {
            text: 'Learn More',
          },
        },
      },
      name: {
        label: 'Weddings Card',
        group: 'Weddings',
      },
      templates: [],
    })
  },
}
</script>

<template>
  <div class="p-4">
    <div class="shadow h-full">
      <div class="flex flex-col justify-between relative mb-10 md:mb-0 pb-8 bg-abs-white w-full h-full">
        <div class="mb-auto">
          <div class="relative overflow-hidden mb-4">
            <VoixMedia v-slot="slotProps" :field="fields.image" background>
              <div
                :style="{ backgroundImage: `url(${slotProps.image})` }"
                class="bg-cover bg-center relative h-64"
                style="min-height:200px;"
              />
            </VoixMedia>
          </div>

          <div class="p-2">
            <div class="text-xl text-center mb-4">
              {{ fields.title.value }}
            </div>
            <div class="wysiwyg text-center text-sm px-2" v-html="fields.copy?.value" />
          </div>
        </div>

        <div class="mt-auto text-center">
          <a
            v-if="!fields.learnMore.enabled" :href="fields.link?.value?.href"
            class="uppercase text-center w-auto rounded-full bg-hyatt-wedding-action px-6 py-2 text-sm text-abs-white"
          >
            {{ fields.link?.value?.value }}
          </a>

          <LearnMore
            v-if="fields.learnMore.enabled" class="text-center" :learn-more="fields.learnMore.value"
          >
            <button
              class="uppercase text-center w-auto rounded-full bg-hyatt-wedding-action px-6 py-2 text-sm text-abs-white"
            >
              {{ fields.link?.value?.text }}
            </button>
          </LearnMore>
        </div>
      </div>
    </div>
  </div>
</template>
