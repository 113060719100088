<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sproutLoudToken: '0',
    }
  },
  computed: {
    overrideStyles() {
      if (this.fields.includedBackground) {
        return {
          backgroundColor: this.fields.includedBackground.value,
          color: this.fields.includedForeground.value,
        }
      }
      return {}
    },
    hasAnImage() {
      if (this.fields.topImage.url || this.fields.topImage2.enabled || this.fields.topImage3.enabled || this.fields.topImage4.enabled || this.fields.topImage5.enabled)
        return true

      return false
    },
  },
  mounted() {
    defineSlice({ slots: [{ name: 'default' }], fields: { topImage: { type: 'media', label: 'Top Image 1', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } } }, topImage2: { type: 'media', label: 'Top Image 2', enabled: false, breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } } }, topImage3: { type: 'media', label: 'Top Image 3', enabled: false, breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } } }, topImage4: { type: 'media', label: 'Top Image 4', enabled: false, breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } } }, topImage5: { type: 'media', label: 'Top Image 5', enabled: false, breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } } }, included: { type: 'text', label: 'Included Banner Text', default: '"Included"', enabled: false }, includedBackground: { type: 'color', label: 'Included Banner BG Color' }, includedForeground: { type: 'color', label: 'Included Banner FG Color' }, copyImage: { type: 'media', label: 'Image in Copy', enabled: false }, title: { type: 'text', label: 'Title', editorLabel: true }, copy: { type: 'wysiwyg', label: 'Copy' }, button: { type: 'link', label: 'Button Link', enabled: false } }, name: { label: 'Sproutloud Sso Block', group: 'Agents Portal' }, templates: [{ label: 'Sproutloud Sso Block', fields: { button: { value: { text: 'GET STARTED', target: '_self', relationship: null, href: '\/new-page' } }, copy: { value: '<p>Want to personalize your marketing efforts? Visit our custom marketing center for personalized flyers and more.<\/p>' }, title: { value: 'CUSTOMIZED MARKETING CENTER' }, topImage: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Agents_Portal\/marketing-resource-center.jpg' } } }] })
    axios.get('/sproutloud-token')
      .then((response) => {
        this.sproutLoudToken = response.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>

<template>
  <div class="mb-8 flex justify-stretch">
    <div class="md:mx-4 bg-white shadow rounded relative w-full">
      <div class="flex flex-col overflow-hidden">
        <div>
          <div class="relative">
            <GeneralSwiper v-if="hasAnImage">
              <VoixMedia v-slot="slotProps" :field="fields.topImage" background>
                <div
                  v-if="fields.topImage.value?.[0]?.url"
                  class="bg-cover bg-center relative"
                  :style="{ backgroundImage: `url(${slotProps.image})` }"
                  style="min-height:300px;"
                />
              </VoixMedia>
              <VoixMedia
                v-if="fields.topImage2.enabled" v-slot="slotProps" :field="fields.topImage" background
              >
                <div
                  class="bg-cover bg-center relative"
                  :style="{ backgroundImage: `url(${slotProps.image})` }"
                  style="min-height:300px;"
                />
              </VoixMedia>
              <VoixMedia
                v-if="fields.topImage3.enabled" v-slot="slotProps" :field="fields.topImage" background
              >
                <div
                  class="bg-cover bg-center relative"
                  :style="{ backgroundImage: `url(${slotProps.image})` }"
                  style="min-height:300px;"
                />
              </VoixMedia>
              <VoixMedia
                v-if="fields.topImage4.enabled" v-slot="slotProps" :field="fields.topImage" background
              >
                <div
                  class="bg-cover bg-center relative"
                  :style="{ backgroundImage: `url(${slotProps.image})` }"
                  style="min-height:300px;"
                />
              </VoixMedia>
              <VoixMedia
                v-if="fields.topImage5.enabled" v-slot="slotProps" :field="fields.topImage" background
              >
                <div
                  class="bg-cover bg-center relative"
                  :style="{ backgroundImage: `url(${slotProps.image})` }"
                  style="min-height:300px;"
                />
              </VoixMedia>

              <template #previous>
                <IconsLeftArrow color="#fff" :w="50" :h="50" />
              </template>
              <template #next>
                <IconsRightArrow color="#fff" :w="50" :h="50" />
              </template>
            </GeneralSwiper>
            <div
              v-if="fields.included.enabled"
              class="included absolute "
            >
              <div
                class="flex justify-center items-center uppercase text-sm text-abs-white bg-currentsite-background"
                :style="overrideStyles"
              >
                <div class="font-bold">
                  {{ fields.included.value }}
                </div>
              </div>
            </div>
          </div>

          <div class="p-8 pb-12">
            <img
              v-if="fields.copyImage.enabled"
              :src="fields.copyImage.value?.[0]?.url"
              :alt="fields.copyImage.value?.[0]?.description"
              class="mb-4"
            >
            <h2 v-if="fields.title.value" class="mb-2 uppercase font-light">
              {{ fields.title.value }}
            </h2>
            <div
              class="wysiwyg mb-4"
              v-html="fields.copy.value"
            />

            <slot />
          </div>
        </div>

        <a
          v-if="sproutLoudToken === '0'"
          :href="fields.button.value?.href"
          :target="fields.button.value?.target"
          class="btn absolute bottom-0 left-0 right-0 mx-0 rounded-b"
        >
          {{ fields.button.value?.text }}
        </a>

        <form v-else method="POST" action="https://app.sproutloud.com/seamless_login" target="_blank">
          <input v-model="sproutLoudToken" type="hidden" name="login_data">
          <input
            type="submit"
            class="btn w-full absolute bottom-0 left-0 right-0 mx-0 rounded-b"
            href="javascript:void(0)"
            :value="fields.button.value?.text"
          >
        </form>
      </div>
    </div>
  </div>
</template>
