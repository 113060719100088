<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      description: 'Is the address and link to contact information flanking three social media icons.',
      fields: {
        address: { type: 'text', label: 'Address' },
        contactLink: { type: 'link', label: 'Contact Information Link' },
        facebookLink: { type: 'link', label: 'Facebook Link' },
        youtubeLink: { type: 'link', label: 'YouTube Link' },
        twitterLink: { type: 'link', label: 'Twitter Link' },
        instagramLink: { type: 'link', label: 'Instagram Link' },
        color: { type: 'color', label: 'Color', default: '#404040' },
        miniMode: { type: 'checkbox', label: 'Display In Mini Mode', default: false },
      },
      name: {
        label: 'Contact Block',
        group: 'Global',
      },
      templates: [{ label: 'Contact Block', fields: { address: { value: 'Boulevard Zona Hotelera, Punta Cana 23302, Dominican Republic' }, contactLink: { value: { text: 'All Contact Information', target: '_self', relationship: null, href: '\/new-page' } }, instagramLink: { value: { text: 'https:\/\/www.instagram.com\/sanctuarycapcana\/', target: '_blank', relationship: null, href: '\/new-page' } }, twitterLink: { value: { text: '', target: '_blank', relationship: null, href: '\/new-page' } }, youtubeLink: { value: { text: 'YOUTUBE', target: '_self', relationship: null, href: '\/new-page' } } } }],
    })
  },
}
</script>

<template>
  <div class="flex justify-center p-6 md:p-8 contact-block">
    <div v-if="!fields.miniMode.value" class="container md:border border-grey-darker md:p-8 flex flex-col md:flex-row justify-between" :style="{ color: fields.color.value, borderColor: fields.color.value }">
      <div>
        <h5 class="uppercase mb-2" :style="{ color: fields.color.value }">
          Resort Contact
        </h5>
        <p class="uppercase mb-4">
          {{ fields.address.value }}
        </p>
        <a :href="fields.contactLink.value?.href" :style="{ color: fields.color.value, borderColor: fields.color.value }" class="inline-block btn btn-ghost block mb-4 md:mb-0">{{ fields.contactLink.value?.text }}</a>
      </div>
      <div>
        <h5 class="uppercase mb-4" :style="{ color: fields.color.value }">
          Connect with Us
        </h5>
        <div class="flex">
          <a v-if="fields.facebookLink.value?.href" class="inline-block text-sm border-4 p-4 rounded-full mr-4" :href="fields.facebookLink.value?.href" aria-label="Facebook Link" target="_blank" :style="{ borderColor: fields.color.value }">
            <IconsFacebook :w="25" :h="25" color="fields.color.value" />
          </a>
          <a v-if="fields.youtubeLink.value?.href" class="inline-block text-sm border-4 p-4 rounded-full mr-4" :href="fields.youtubeLink.value?.href" aria-label="YouTube Link" target="_blank" :style="{ borderColor: fields.color.value }">
            <IconsYouTube :w="25" :h="25" color="fields.color.value" />
          </a>
          <a v-if="fields.twitterLink.value?.href" class="inline-block text-sm border-4 p-4 rounded-full mr-4" :href="fields.twitterLink.value?.href" aria-label="Twitter Link" target="_blank" :style="{ borderColor: fields.color.value }">
            <IconsTwitter :w="25" :h="25" color="fields.color.value" />
          </a>
          <a v-if="fields.instagramLink.value?.href" class="inline-block text-sm border-4 p-4 rounded-full" :href="fields.instagramLink.value?.href" aria-label="Instagram Link" target="_blank" :style="{ borderColor: fields.color.value }">
            <IconsInstagram :w="25" :h="25" color="fields.color.value" />
          </a>
        </div>
      </div>
    </div>
    <div v-else class="w-full md:w-1/3 mb-10 container border-t border-b border-grey-darker p-2 flex flex-col md:flex-row justify-center items-center" :style="{ color: fields.color.value, borderColor: fields.color.value }">
      <ul class="flex justify-center text-center items-center list-reset">
        <li>
          <a :href="fields.contactLink.value?.href" class="text-grey-darkest pb-0 text-lg border-r border-grey-darkest pr-2">{{ fields.contactLink.value?.text }}</a>
        </li>
        <li>
          <span class="pb-0 text-lg px-2">Follow Us</span>
        </li>
        <li v-if="fields.facebookLink.value?.href">
          <a v-if="fields.facebookLink.value?.href" class="block mt-1 text-sm mr-2" :href="fields.facebookLink.value?.href" target="_blank" aria-label="Facebook Link" :style="{ borderColor: fields.color.value }">
            <IconsFacebook :w="20" :h="20" color="fields.color.value" />
          </a>
        </li>
        <li v-if="fields.youtubeLink.value?.href">
          <a v-if="fields.youtubeLink.value?.href" class="block mt-1 text-sm mr-2" :href="fields.youtubeLink.value?.href" target="_blank" aria-label="YouTube Link" :style="{ borderColor: fields.color.value }">
            <IconsYouTube :w="20" :h="20" color="fields.color.value" />
          </a>
        </li>
        <li v-if="fields.twitterLink.value?.href">
          <a v-if="fields.twitterLink.value?.href" class="block mt-1 text-sm mr-2" :href="fields.twitterLink.value?.href" target="_blank" aria-label="Twitter Link" :style="{ borderColor: fields.color.value }">
            <IconsTwitter :w="20" :h="20" color="fields.color.value" />
          </a>
        </li>
        <li v-if="fields.instagramLink.value?.href">
          <a v-if="fields.instagramLink.value?.href" class="block mt-1 text-sm" :href="fields.instagramLink.value?.href" target="_blank" aria-label="Instagram Link" :style="{ borderColor: fields.color.value }">
            <IconsInstagram :w="20" :h="20" color="fields.color.value" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
